//Rails 6.1
import consumer from './consumer'

//runs on every page load
//document.addEventListener('turbolinks:load', () => {

function startChat(auth_token,url,stream_id,user_id) {
  //console.log("chat_channel.js ran")
  console.log("starting chat...")

  //collect rails data through html chats/_list_messages
  const chat_element = document.getElementById('chat-box');

  if (chat_element) {
    //const stream_id = stream_element.getAttribute('stream-id');
    //console.log('found stream element, creating subscription for ' + stream_id);

    //delete all other open subscriptions here
    consumer.subscriptions.subscriptions.forEach((subscription) => {
      consumer.subscriptions.remove(subscription)
    });

    //move to bottom of chat box conversation
    //can't calculate perfect height when photos present...
    var messages = $('#messages')
    messages.scrollTop(messages[0].scrollHeight);

    //create the js chat app "channel"
    //Rails 6.1
    const channel = consumer.subscriptions.create(
      { channel: "ChatChannel", stream_id: stream_id },
      {
      connected: function(data) {    //function not required for some reason
        console.log("connected to flb chat stream: " + stream_id)
      },

      disconnected: function(data) {  //function required for some reason
        console.log("disconnected from bff chat stream: " + stream_id)
      },

      received: function(data) {
        //console.log("received msg on bff chat stream: " + data);
        //$('#chat-dot').attr('class', 'dot-on');
        let html;
        if (user_id === data.chat.user_id) {
          html = data.mine
        } else {
          html = data.them
        }

        //['chat'] is the object name from the sidekiq job
        if (data['chat'])
          {
            messages.append(html) //data['chat']
            messages.scrollTop(messages[0].scrollHeight)
          };
        },

      send_message: function(message_body, message_filename) {
          //updateMiles();
          //console.log("sending msg for bff chat");
          //ChatChannel#send_message({"message"=>"test msg", "stream_id"=>"private-1-20"})
          //"perform" means run app/channels/ChatChannel.rb#send_message method, not a sidekiq job
          this.perform('send_message', { message: message_body, filename: message_filename, stream_id: stream_id });
          body.val('')
          filename.val('')
        }

      }); //end create({x,y},{a,b,c})

      //allow close_chat and others to access the js app
      window.channel = channel;

      //#find the form in the page
      var chat_form = $('#chat-form')
      var file_form = $('#file-form')

      //#find the input fields in the forms
      var body = $('#body')  //input field for text in form
      var filename = $('#filename') //input field for files in form

      //this runs when you submit the chat form
      chat_form.submit(function(e) {
        //console.log("chat form submitted")
        var message_body = body.val()
        var message_filename = "" //filename.val().split("\\")[2]  //"C:\\fakepath\\Hands0ff.png"]]"
        if (message_body.length > 0 || message_filename.length > 0)
          { channel.send_message(message_body, message_filename) }
        e.preventDefault()
        return false
      });

      //this runs when you submit the file form
      file_form.submit(function(e) {
        //console.log("file form submitted")
        var message_body = ""
        var message_filename = filename.val().split("\\")[2]
        let file = e.target.filename.files[0]

        //caution: send_message clears filename var
        if (message_body.length > 0 || message_filename.length > 0)
          { channel.send_message(message_body, message_filename) }

        let formData = new FormData()
        formData.append('file', file)
        formData.append('stream_id', stream_id)
        formData.append('authenticity_token', auth_token)

        fetch(url, {
           method: 'POST',
           body: formData
        })
        .then(data => {
           if (data.errors) {
              alert(data.errors)
           } else {
              console.log("chat file upload: " + data.status)
           }
        })
        e.preventDefault()
        return false
      }); //end function


  } else {

    console.log("could not find stream element");

  }//if
}//startChat

//allows you to call this method from .js.erb files in the view
window.startChat = startChat;


//}); //turbo links
